import { type ReactNode, createContext, useCallback, useContext } from 'react'
import { useRouter } from 'next/router'

import { getLinkPageUrl } from './routes'
import { SiteContext } from './site'

export type Locale = 'en' | 'da'

interface LanguageContextProps {
  locale: Locale
  publicLocales: Locale[]
  changeLocale: (newLocale: Locale) => void
}

interface LanguageContextProviderProps {
  locale: Locale
  publicLocales: string[]
  children: ReactNode
}

export const defaultLocale: Locale = 'en'

export const localeNames: Record<Locale, string> = {
  da: 'Dansk',
  en: 'English',
}

/**
 * Gets all locales.
 */
export const getAllLocales = () =>
  Object.keys(localeNames).map((value) => value as Locale)

const initialContext: LanguageContextProps = {
  locale: defaultLocale,
  publicLocales: [],
  changeLocale: () => null,
}

export const LanguageContext =
  createContext<LanguageContextProps>(initialContext)

export const LanguageContextProvider = ({
  locale,
  publicLocales,
  children,
}: LanguageContextProviderProps) => {
  const router = useRouter()
  const { toggleMegaNavigation, toggleMobileMenu } = useContext(SiteContext)

  // Handle language change
  const changeLocale = useCallback(
    (newLocale: Locale) => {
      toggleMegaNavigation(false)
      toggleMobileMenu(false)

      if (locale !== newLocale) {
        const homeUrl = getLinkPageUrl('homePage')
        router.push(homeUrl, homeUrl, {
          locale: newLocale,
        })
      }
    },
    [locale, router, toggleMegaNavigation, toggleMobileMenu]
  )

  return (
    <LanguageContext.Provider
      value={{
        locale,
        publicLocales: publicLocales as Locale[],
        changeLocale,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
